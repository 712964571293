@import 'src/styles/utils';

.MerchantCalendarPage {
  padding-bottom: layout-unit(5);

  .MerchantCalendarPage__Heading {
    margin-bottom: layout-unit(1);
    border-left: 3px solid #cdcdcd;
  }

  .MerchantCalendarPage__MerchantProfileSection {
    padding: layout-unit(6) layout-unit(1);
  }

  .MerchantCalendarPage__BackToTopButtonSection {
    margin-top: layout-unit(1);
    text-align: center;
  }

  .MerchantCalendarPage__BackToTopButton {
    box-sizing: border-box;
    max-width: 370px;
    padding: layout-unit(0.5) layout-unit(0.25);
  }

  @include media(pc) {
    padding-bottom: 0;

    .MerchantCalendarPage__MerchantProfileSection {
      padding: layout-unit(6) 0;
    }

    .MerchantCalendarPage__BackToTopButtonSection {
      display: none;
    }
  }
}
