.select {
  display: block;
  width: 100%;
  height: 34px;
  margin: 12px 0;
  padding: 6px, 12px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #777;
  font-size: 14px;
  line-height: 1.5;
}
