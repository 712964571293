@import 'src/styles/utils';

.MerchantProfile {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  color: #333;
  font-size: 14px;

  a {
    color: #0066ff;
    text-decoration: none;
  }

  dd,
  dt,
  tr,
  td {
    margin: 0;
  }

  .MerchantProfile__Row {
    display: flex;
    flex-direction: column;
    padding: layout-unit(1);

    & + .MerchantProfile__Row {
      border-top: 1px solid #ddd;
    }
  }

  .MerchantProfile__RowHead {
    padding: 0 0 layout-unit(1);
    font-weight: bolder;
  }

  .MerchantProfile__RowContent {
    flex: 1;
    white-space: pre-wrap;
  }

  .MerchantProfile__RowBodySubLink {
    display: inline-block;
    padding: layout-unit(0.75) 0 0;
  }

  .MerchantProfile__BusinessHourText {
    margin: 0;

    & + .MerchantProfile__BusinessHourText {
      padding: layout-unit(0.5) 0 0;
    }
  }

  .MerchantProfile__TemporaryDateTextCell {
    width: 160px;
    padding-bottom: 10px;
    vertical-align: top;
    line-height: 21px;
  }

  .MerchantProfile__TemporaryDateSubTextCell {
    padding-bottom: 10px;
    line-height: 21px;
  }

  @include media(pc) {
    a:hover {
      opacity: 0.7;
    }

    .MerchantProfile__Row {
      flex-direction: row;
    }

    .MerchantProfile__RowHead {
      width: layout-unit(10);
      padding: 0 layout-unit(1) 0 0;
    }
  }
}
